import { initializeApp } from 'firebase/app';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyBz7ADyLgdC1M2tt6lqo-x6ytjTvdMvAro',
  authDomain: 'saurs-97e47.firebaseapp.com',
  projectId: 'saurs-97e47',
  storageBucket: 'saurs-97e47.appspot.com',
  messagingSenderId: '766408140198',
  appId: '1:766408140198:web:8728e20c0e552ed9f29b67',
  measurementId: 'G-KBNVG862VS',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export {
  auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
};
