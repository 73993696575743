import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  LoadingOverlay,
  Alert,
} from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { IconAlertCircle } from '@tabler/icons-react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { signInWithEmailAndPassword, auth } from 'firebaseNextjs/clientApp';

export default function Login() {
  const router = useRouter();
  const [loadingState, setLoadingState] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (!loading && user?.email) {
      router.push('/dashboard');
    }
  }, [user]);

  const handleLogin = async (values: any) => {
    setLoadingState(true);
    try {
      const { email, password } = values;
      await signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      setErrorForm(true);
    }
    setLoadingState(false);
  };

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: (value: string) => (/^\S+@\S+$/.test(value) ? null : 'Email non valida'),
      password: isNotEmpty('Inserire una password'),
    },
  });
  return (
    <Container size={420} my={40}>
      <Title
        align="center"
        sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
      >
        Bentornato!
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Non hai ancora un account?{' '}
        <Anchor<'a'> href="/login/register" size="sm">
          Crea un account
        </Anchor>
      </Text>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <LoadingOverlay visible={loadingState || loading} overlayBlur={2} />
        {errorForm && (
          <Alert icon={<IconAlertCircle size={16} />} title="Errore!" color="red">
            Nome utente o password errati
          </Alert>
        )}
        <form onSubmit={form.onSubmit(handleLogin)}>
          <TextInput
            label="Email"
            placeholder="La tua email"
            data-testid="login-input-email"
            required
            {...form.getInputProps('email')}
          />
          <PasswordInput
            label="Password"
            placeholder="La tua password"
            data-testid="login-input-password"
            required
            mt="md"
            {...form.getInputProps('password')}
          />
          <Group position="apart" mt="lg">
            <Checkbox label="Remember me" sx={{ lineHeight: 1 }} />
            <Anchor<'a'> href="/login/recover" size="sm">
              Password dimenticata?
            </Anchor>
          </Group>
          <Button fullWidth mt="xl" type="submit" data-testid="login-input-submit">
            Accedi
          </Button>
        </form>
        <Anchor<'a'> href="/" size="sm" ta="center" mt={15} style={{ display: 'block' }}>
          Torna in Homepage
        </Anchor>
      </Paper>
    </Container>
  );
}
